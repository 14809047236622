<template>
  <div>
    <div>抽奖管理</div>
    <div class="btns">
      <el-button @click="add">新增</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" height="600">
      <el-table-column prop="index" label="序号" width="100" />
      <el-table-column prop="name" label="奖项名称"/>
      <el-table-column prop="des" label="描述" />
      <el-table-column prop="person" label="抽取人数"  width="100"/>
      <el-table-column>
        <template #default="scope">
          <el-button link type="primary" size="small" @click="edit(scope.row)">修改</el-button>
          <el-button link type="danger" size="small" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination hide-on-single-page small background layout="prev, pager, next" :total="20" class="mt-4"/>
    </div>

    <el-dialog
      v-model="visible"
      title="添加奖项"
      width="40%"
      :before-close="close"
    >
    <el-form
      label-width="100px"
      style="max-width: 460px"
    >
      <el-form-item label="名称">
        <el-input v-model="formLabelAlign.name" />
      </el-form-item>
      <el-form-item label="抽取人数">
        <el-input v-model="formLabelAlign.num" />
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="formLabelAlign.des" />
      </el-form-item>
    </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import { onMounted, ref, reactive } from 'vue';

  // api
  import { GET_REWARD_LIST } from '@/api';

  const tableData = ref([{index: 1, name: '测试', des: '测试', person: 10}]);
  const formLabelAlign = reactive({
    name: '',
    num: '',
    des: '',
  })
  const visible = ref(false);
  onMounted(() => {
    init();
  });

  const init = async () => {
    const res = await GET_REWARD_LIST();
    console.log(res);
  }

  const add = () => {
    formLabelAlign.name = '';
    formLabelAlign.num = '';
    formLabelAlign.des = '';
    visible.value = true;
  }

  const close = () => {
    visible.value = false;
  }

  const edit = () => {
    visible.value = true;
  }

  const del = () => {

  }

  const submit = () => {
    console.log('提交', formLabelAlign);
    visible.value = false;
  }
  
</script>

<style lang="scss" scoped>
.pagination{
  padding: 10px 10px 10px 0;
  display: flex;
  justify-content: end;
}
</style>